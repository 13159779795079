import './App.css';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/website/HomePage'; 
import ContactPage from './pages/website/ContactPage';
import LoginPage from './pages/website/LoginPage';
import QrMenuPage from './pages/qrmenu/QrMenuPage';

function App() {

  return (
      <div className="flex flex-col justify-between bg-[#08090A] text-[#D9E5D6] subpixel-antialiased bg-fixed bg-food-pattern ">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/contact" element={<ContactPage/>} />
          <Route path="/login" element={<LoginPage/>} />
          <Route path="/menu/:id" element={<QrMenuPage/>} />
        </Routes>
        <Footer />
      </div>
  );
}

export default App;
