import React from 'react';
import { MapPinIcon, DevicePhoneMobileIcon, ClockIcon, StarIcon } from '@heroicons/react/24/solid'

type BusinessCardProps = {
    businessData: any | null;
};

const BusinessCard: React.FC<BusinessCardProps> = ({businessData}) => {
    return (
        <div className="m-5 text-center">
            <img className="inline-block h-40 w-40 rounded-full ring-2  ring-white my-2" src={businessData?.logo} alt="Snacks Trugo Logo"/>
            <h1 className="text-3xl font-bold my-2 text-[#EF6F6C] font-pacifico">
                - {businessData?.name} -
            </h1>
            <StarIcon className="size-5 mx-auto text-yellow-500 my-5" />
            <p className="text-sm"><DevicePhoneMobileIcon className="size-4 inline mr-2 my-2" />{ businessData?.phone }</p>
            <p className="text-sm"><MapPinIcon className="size-4 inline mr-2 my-2" />
                {businessData?.address.street}<br />
                C.P. {businessData?.address?.zipCode}, {businessData?.address?.city}, {businessData?.address?.state}. 
            </p>
            <p className="text-sm my-2">
                <ClockIcon className="size-4 inline mr-2 my-2" />
                {businessData?.schedule.map((item: any, index: number) => (
                    <label key={index}>{item.day} {item.hours}. </label>
                ))}
            </p>
        </div>
    );
};

export default BusinessCard;