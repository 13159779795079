import React from 'react';
import { StarIcon } from '@heroicons/react/24/solid'

type MenuWrapperProps = {
    dishes: any[] | null;
};

const MenuWrapper: React.FC<MenuWrapperProps> = ({dishes}) => {
    // Add your component logic here

    return (
        <div className="my-5">
            <StarIcon className="size-5 mx-auto text-yellow-500 my-5" />
            <h1 className="text-center font-bold text-3xl font-pacifico my-5 text-[#EF6F6C]">- Menú -</h1>
            {dishes?.map((dish, index) => (
                <div key={index} className="m-5">
                    <h2 className="font-bold text-xl">{dish.groupName}</h2>
                    <div className="text-yellow-500 font-bold">
                        {dish.groupOptions?.map((option:any, index:number) => (
                            <li key={index} className="py-1 text-sm inline">{option.name}. </li>
                        ))}
                    </div>
                    <div className="ml-3 my-2">
                        {dish.Dishes.map((dish:any, index:number) => (
                            <div key={index} className="border-b-2 border-slate-700 border-dotted my-1 py-1">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <h3 key={index}>{dish.name}</h3>
                                    <label className="font-bold text-yellow-500">
                                        {new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                        }).format(dish.price)}
                                    </label>
                                </div>
                                <p className="text-yellow-500 text-sm">{dish.portion} {dish.portionUnit}</p>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default MenuWrapper;