import React from 'react';

const LoginPage: React.FC = () => {
    return (
        <main className="container mx-auto mb-auto mt-16 min-h-96">
            <div className="m-5">
                <h1>Login Page</h1>
            </div>
        </main>
    );
};

export default LoginPage;