import { useState, useEffect } from 'react';
import {customersRef} from '../lib/firebase';
import { get } from 'firebase/database';


const useFirebaseData = (businessId: string | undefined) => {
    console.log(businessId)
    const [businessData, setBusinessData] = useState(null);
    const [menuData, setMenuData] = useState(null);
    const [notesData, setNotesData] = useState(null); 
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const handleError = (error:any) => {
            setError(error);
        };
        const getFbData = async () => {           
            try {
                if (businessId !== undefined) {
                    const fetchedData = await get(customersRef(businessId));
                    if (fetchedData.val()) {
                        
                        setBusinessData(fetchedData.val().business);
                        setMenuData(fetchedData.val().menu);
                        setNotesData(fetchedData.val().notes);
                    } else {   
                        handleError("Negocio no registrado.");
                    }
                } else {
                    handleError("No existe el id del negocio.");
                }
            } catch (error: any) {
                handleError(error.message);
            } finally {
                setLoading(false);
            }
        }
        getFbData();
    }, [businessId]);

    return { businessData, menuData, notesData, loading, error };
};

export default useFirebaseData;