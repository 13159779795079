import React from 'react';
import WebHeader from '../../components/website/WebHeader'; 
import WebSection from '../../components/website/WebSection';

const HomePage: React.FC = () => {
    return (
        <main className="container mx-auto mb-auto mt-16 min-h-96">
            <div className="p-5 bg-[#08090A]">
            <WebHeader/>    
            <WebSection/>
            <WebSection/>
            <WebSection/>
            <WebSection/>
            <WebSection/>
            </div>
        </main>
    );
};

export default HomePage;