import { Bars3Icon } from '@heroicons/react/24/solid';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';


const MenuWebsite: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="flex justify-end ml-4">
            <div className="block sm:hidden">
                <button
                    onClick={toggleMenu}
                    className="flex items-center px-3 py-2 border rounded text-[#EF6F6C] border-[#EF6F6C] hover:text-[#EF6F6C] hover:border-[#EF6F6C]"
                >
                    <Bars3Icon className="size-4 text-[#EF6F6C] text-bold" />
                </button>
            </div>
            <div
                className={`${
                    isOpen ? 'block' : 'hidden'
                } sm:flex sm:items-center sm:ml-6`}
            >
                <Link to="/" className="block px-4 py-2 hover:text-[#EF6F6C]">Inicio</Link>
                {/* <Link to="/contact" className="block px-4 py-2 hover:text-[#EF6F6C]">Contacto</Link> */}
                {/* <Link to="/login" className="block px-4 py-2 hover:text-[#EF6F6C]">Iniciar Sesión</Link>
                <Link to="/menu/:businessId" className="block px-4 py-2 hover:text-[#EF6F6C]">Trugo</Link> */}
            </div>
        </nav>
    );
};

export default MenuWebsite;
