import { StarIcon } from '@heroicons/react/24/solid';
import React from 'react';


const Loader: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
    if (!isLoading) {
        return null;
    }

    return (
        <div className="flex justify-center items-center h-content absolute top-0 left-0 right-0 bottom-0 bg-black opacity-70">
            <div className="animate-spin rounded-full h-20 w-20 border-t-4 border-b-4 border-[#EF6F6C]"><StarIcon className="size-10 mx-auto mt-3 pt-1 text-[#EF6F6C]" /></div>
        </div>
    );
};


export default Loader;