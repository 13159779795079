import { initializeApp } from "firebase/app";
import { getDatabase, ref } from "firebase/database";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBHsPo8o3zhFofJ-wFQGimiwRgMOtMBp4I",
  authDomain: "wrs-pidetumenu.firebaseapp.com",
  databaseURL: "https://wrs-pidetumenu-default-rtdb.firebaseio.com",
  projectId: "wrs-pidetumenu",
  storageBucket: "wrs-pidetumenu.appspot.com",
  messagingSenderId: "996398425613",
  appId: "1:996398425613:web:fa7a8b519a12e1f7af9744",
  measurementId: "G-BZX94VPETH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

getAnalytics(app);

const customersRef = (child?: string) => {
  let jsonPathUrl = "/customers/";
  if (child) jsonPathUrl += child;
  return ref(db, jsonPathUrl);
};

export { db, customersRef };
