import React from 'react';
import Content from '../../components/layout/Content';

const QrMenuPage: React.FC = () => {
    return (
        <main className="container mx-auto mb-auto mt-16">
            <Content />
        </main>
    );
};

export default QrMenuPage;