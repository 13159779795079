import { ShareIcon } from '@heroicons/react/24/solid';
import React from 'react';

type CompProps = {};

const ShareButton: React.FC<CompProps> = () => {
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: '¡Se ve bueno este menú!',
          text: 'Snacks Trugo en pidetumenu.com',
          url: 'https://pidetumenu.com/menu/snacks-trugo?qrid=240518',
        });
        console.log('Content shared successfully');
      } catch (error) {
        console.error('Error sharing content:', error);
      }
    } else {
      alert('No es posible compartir por este medio, copia pasa el link a tus amigos.');
    }
  };

return (
    <button onClick={handleShare} className="text-white mx-auto p-3 rounded-full border border-[#EF6F6C]">
        <ShareIcon className="size-5 mx-auto text-[#EF6F6C]" />
    </button>
);
};

export default ShareButton;
