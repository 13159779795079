import React from 'react';
import { StarIcon } from '@heroicons/react/24/outline'

const Footer: React.FC = () => {
    const currentYear = new Date().getFullYear();

    return (
      <footer className="bg-[#08090A] p-5 text-center">
        <div className="container mx-auto  text-sm">
          <div className="my-5">
            <p>Crea el menú para tu negocio y te ayudamos a crear presencia digital en</p>
            <p><a className="underline" href="https://pidetumenu.com">https://pidetumenu.com</a></p>
            <StarIcon className="size-5 mx-auto my-5" />
          </div>
          <div className="my-5">
            <p className="font-bold">{currentYear}</p>
            <p>Todos los derechos reservados</p>
            <p ><a className="underline" href="https://webrocket.services?ref-site=pidetumenu.com">https://webrocket.services</a></p>
          </div>
      </div>
      </footer>
    );
};

export default Footer;