import React from 'react';
import { Link } from 'react-router-dom';

interface MenuQrMenuProps {
}

const MenuQrMenu: React.FC<MenuQrMenuProps> = (props) => {
    return (
        <nav className="flex justify-end ml-4">
            <Link to="#" className="block px-4 py-2 text-[#EF6F6C] font-pacifico">Snacks Trugo</Link>
            </nav>
    );
};

export default MenuQrMenu;