import React from 'react';

type WebSectionProps = {
};

const WebSection: React.FC<WebSectionProps> = (props) => {
    return (
        <section className="py-12">
            <h2>Bienvenido a una seccion</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam aliquam, nunc id ultrices aliquet, nisl nunc tincidunt nunc, id aliquam nunc nunc id nunc. Sed auctor, nunc id ultrices aliquet, nisl nunc tincidunt nunc, id aliquam nunc nunc id nunc.</p>
        </section>
    );
};

export default WebSection
