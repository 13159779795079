import React from 'react';

type CompProps = {
};

const WebHeader: React.FC<CompProps> = (props) => {
    return (
        <section>
            <h1>Bienvenido a pidetumenu.com!</h1>
        </section>
    );
};

export default WebHeader;
