import React from 'react';
// import { StarIcon } from '@heroicons/react/24/solid';
import { useLocation } from 'react-router-dom';
import ShareButton from './ShareButton';

interface MenuNotesProps {
    notes: string[] | null;
}

const MenuNotes: React.FC<MenuNotesProps> = ({ notes }) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const qrid = searchParams.get('qrid');

    return (
        <>
            <div className="m-5 text-right">
                {/* <StarIcon className="size-5 mx-auto text-yellow-500 my-5" /> */}
                {notes?.map((note, index) => (
                    <p key={index} className="text-sm">{note}</p>
                ))}
                <p className="text-sm text-center text-gray-700 my-5">
                {qrid !== null ? <label>#{qrid}</label> : ""}
                </p>
            </div>
            <div className="m-5 text-center">

                <ShareButton/>
            </div>
        </>
    );
};

export default MenuNotes;