import React from 'react';
import MenuWebsite from './MenuWebsite';
import MenuQrMenu from './MenuQrMenu';
import { Link, useLocation } from 'react-router-dom';

interface HeaderProps {
}


const Header: React.FC<HeaderProps> = (props) => {
  const location = useLocation();
  const isMenuPage = location.pathname.includes('/menu');
  
    return (
      <header className="w-full fixed top-0 bg-[#08090A] p-4 border-b-2 border-[#000]">
        <div className="container mx-auto flex justify-between">
            <h1 className="font-bold"><Link to="/" className="block px-4 py-2 hover:text-[#EF6F6C]">pidetumenu.com</Link></h1>
            {isMenuPage ? <MenuQrMenu /> : <MenuWebsite/>}
        </div>
      </header>
    );
};

export default Header;