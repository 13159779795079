import React from 'react';
import MenuWrapper from '../qrmenu/MenuWrapper';
import BusinessCard from '../qrmenu/BusinessCard';
import MenuNotes from '../qrmenu/MenuNotes';
import useFirebaseData from '../../hooks/FirebaseHook';
import Loader from './Loader';
import { useParams } from 'react-router-dom';
import ShareButton from '../qrmenu/ShareButton';

type ContentProps = {
    // Define your component props here
};

const Content: React.FC<ContentProps> = () => {
    const { id } = useParams<string>();
    const { menuData,businessData,notesData, loading, error } = useFirebaseData(id);
    
    return (
        <>
            <Loader isLoading={loading} />
            {error || !businessData || !menuData || !notesData ? (
                <div className="m-5 min-h-lvh text-center font-bold py-5">{error}</div>
            ) : (
                <>
                    <BusinessCard businessData={businessData} />
                    <MenuWrapper dishes={menuData} />
                    <MenuNotes notes={notesData} />
                </>
            )}
        </>
    );
};

export default Content;